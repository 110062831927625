<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <div v-show="leagues.length > 0">
                    <div class="btn-group w-100" role="group">
                        <button type="button" class="btn btn-secondary" @click="cat = '90'" :class="{'active': cat == '90'}" :disabled="cat == '90'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">90 minutos</span>
                            <span v-else>90 min</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'pt'" :class="{'active': cat == 'pt'}" :disabled="cat == 'pt'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Primeiro tempo</span>
                            <span v-else>1º tempo</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'st'" :class="{'active': cat == 'st'}" :disabled="cat == 'st'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Segundo tempo</span>
                            <span v-else>2º tempo</span>
                        </button>
                    </div><!-- /btn-group -->
                    <div class="row ml-0 mt-3 mb-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Buscar opção...">
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="save"><i class="fas fa-save mr-2"></i>Salvar alterações</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                    <div class="mt-3 mb-3" v-show="markets.length > 0">
                        <template v-if="filteredMarkets.length == 0">
                            <div class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, nenhum resultado!</h4>
                                <hr />
                                <p class="mb-0">Não encontramos nenhuma opção com o nome "<strong>{{search}}</strong>". Verifique o nome e tente novamente!</p>
                            </div><!-- /alert-danger -->
                        </template>
                        <template v-else>
                            <div class="w-100 clearfix" :class="{'desktop': platform == 'desktop'}">
                                <div class="list-group list-group-flush" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop pr-3': platform == 'desktop', 'rounded': !loading}">
                                    <div class="list-group-item" v-for="(item, index) in filteredMarkets" :key="index" :class="{'bg-danger text-white': String(filteredMarkets[index].active) == 'false'}">
                                        <div class="row" :class="{'justify-content-center': platform == 'mobile'}">
                                            <div class="col text-primary" :class="{'col-12 mb-2 text-center': platform == 'mobile', 'd-flex align-items-center text-truncate': platform == 'desktop', 'cursor-pointer': (item.id == 100 || item.id == 101 || item.id == 102)}" @click="toggleShowRow(item.id)">
                                                <div :class="{'text-truncate': platform == 'desktop', 'bg-cat rounded p-1': platform == 'mobile', 'text-white': String(filteredMarkets[index].active) == 'false'}">{{item.label}}</div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Tipo</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <select class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="filteredMarkets[index].type">
                                                            <option value="1" :selected="item.type == 1">Reduzir</option>
                                                            <option value="2" :selected="item.type == 2">Aumentar</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">%</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <input class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`percent-${index}`" v-model="filteredMarkets[index].percent" v-number-only @paste="noPaste($event)"> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Limite</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <input class="form-control form-control-sm w-limit" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`limit-${index}`" v-model="filteredMarkets[index].limit" v-number-only @paste="noPaste($event)"> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Bloquear</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <select class="form-control form-control-sm" :id="`active-${index}`" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="filteredMarkets[index].active">
                                                            <option value="true" :selected="item.active == true">Não</option>
                                                            <option value="false" :selected="item.active == false">Sim</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                        <template v-if="item.id == 100 || item.id == 101 || item.id == 102">
                                            <div class="card p-2 mt-2 bg-f1f1f1" v-show="showRowID == item.id">
                                                <div class="row align-items-center">
                                                    <div class="text-truncate text-danger" :class="platform == 'desktop' ? 'col' : 'col-12'">Remover escanteios menores que:</div>
                                                    <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-2'">
                                                        <select :id="`blacklist-${index}`" v-model="filteredMarkets[index].blacklist" class="form-control form-control-sm">
                                                            <option v-for="n in 21" :value="n-1" :key="n-1" :selected="item.blacklist == 0">{{n-1}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div><!-- /div -->
                        </template>
                    </div><!-- /mt-3 mb-3 -->
                </div><!-- /div -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const heightAdjustMobile = 315;
const heightAdjustDesktop = 205;
const heightAdjust = isMobile ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            cat: 90,
            error: '',
            search: '',
            showRowID: 0,
            loading: false,
            isLoading: false,
            screen_innerHeight: window.innerHeight - heightAdjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            id: '',
            leagues: [],
            markets: []
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.markets.some(item => item.limit.toString().trim().length == 0 || item.percent.toString().trim().length == 0 || regExp.test(item.limit.toString().trim()) || regExp.test(item.percent.toString().trim()));

            if (filter) {
                Swal.fire(
                    'Atenção',
                    'Preencha todos os campos, utilizando apenas números!',
                    'warning'
                );
            } 
            else 
            {

                self.isLoading = true;
                
                api.post('dashboard/rules-leagues', {
                    id: self.id,
                    markets: self.markets
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        rulesLeague(id) {

            const self = this;

            if (id == '') {
                self.id = '';
                self.markets = [];
            } else {

                self.id = id;
                self.markets = [];
                self.isLoading = true;

                api.get(`dashboard/rules-leagues/${id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.markets = response.data.markets;
                        break;
                        default:
                            self.error = response.data.message;
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.error = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        noPaste(event) {
            return event.preventDefault();
        },
        toggleShowRow(id) {
            const allowedIds = [100, 101, 102];
            if (allowedIds.includes(id)) {
                if (this.showRowID == id) {
                    this.showRowID = 0;
                } else {
                    this.showRowID = id;
                }
            } else {
                this.showRowID = 0;
            }
        }
    },
    computed: {
        filteredMarkets() {
            return this.markets.filter((item) => {
                return item.cat == this.cat && item.label.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9.]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    watch: {
        leagues(leagues) {

            var select = document.querySelector('#virtual-select');

            if (leagues.length) {

                const options = leagues.map((item) => {
                    return {
                        value: item.id,
                        label: item.nome
                    }
                });
                
                select.setOptions(options);
            }
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione uma liga',
            noOptionsText: 'Nenhuma liga encontrada',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar liga...',
            hasOptionDescription: false
        });

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                self.rulesLeague(this.value);
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/leagues').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.leagues = response.data.leagues.sort((a, b) => {
                        return a.nome.localeCompare(b.nome)
                    });
                break;
                case 'nothing_found':
                    self.error = 'Não encontramos nenhuma liga disponível!'
                break;
                default:
                    self.error = response.data.message;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
.w-100.clearfix.desktop .list-group .list-group-item .row .col-auto input[type="text"]{
    max-width: 100px;
}
.w-100.clearfix .list-group.list-group-flush {
    overflow-x: auto;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:hover {
  background: #666666;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:active {
  background: #333333;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-corner {
  background: transparent;
}
.w-100.clearfix .list-group.list-group-flush .col-12 .bg-cat {
    background-color: #e8e8e8;
}
</style>